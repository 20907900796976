<template>
    <div class="container">
        <div class="mt-5 mb-2 fl-te-c card">
             <btn text="<-" class="" @click="$router.go(-1)" size="sm"/>
            <h4 class="text-primary mb-0 font-poppins-semibold">CANDIDATE</h4>
            <div>
                <div class="btn-group">
                <btn size="sm" text="ADD" icon="fa fa-plus"
                     @click="$refs.addcandidate.show();"></btn>
                <btn size="sm" text="Bulk Upload" icon="fa fa-plus"
                     @click="$refs.bulkupload.show();"></btn>
                </div>
            </div>
        </div>
        <div class="card mt-2">
            <div class="row m-0">
                <div class="col-12">
                    <vue-table ref="table" :fields="fields" :url="listURL" :per-page="50">
                        <template slot="actions" slot-scope="props">
                            <div class="btn-group">
                                <btn text="" size="sm" icon="fa fa-pencil" color="primary"
                                     @click="editItem(props.rowData)"/>
                                <btn text="" size="sm" icon="fa fa-trash" color="danger"
                                     @click="deleteItem(props.rowData.id)"/>
                            </div>

                        </template>
                    </vue-table>

                </div>

            </div>
        </div>

        <modal ref="addcandidate" :no-close-on-backdrop="true" title="Candidate" width="40r" header-color="primary" :show-additional-buttons="true">
            <template #buttons>
                <a @click="addClose"><i class="fa fa-close"></i></a>
            </template>
            <s-form @submit="addEditItem">
                <div class="row">
                    <div class="col-lg-12">
                        <validated-input label="First Name" :rules="{required: true}"
                                         class="" border-radius="1" v-model="model.first_name "></validated-input>
                    </div>
                    <div class="col-lg-12">
                        <validated-input label="Last Name" :rules="{required: true}"
                                         class="" border-radius="1" v-model="model.last_name "></validated-input>
                    </div>
                    <div class="col-lg-12">
                        <validated-input label="Email" :rules="{required: true}"
                                         class="" border-radius="1" v-model="model.email " type="email"></validated-input>
                    </div>
                    <div class="col-12 text-right mt-4">
                        <btn text="Save" :loading="addLoading" loading-text="Saving..." class="px-4"></btn>
                    </div>
                </div>
            </s-form>
        </modal>
        <!--        adding bulk upload modal by midhun 10-02-2025-->
        <modal ref="bulkupload" :no-close-on-backdrop="true" title="Bulk Upload" width="40r" header-color="primary" :show-additional-buttons="true">
            <template #buttons>
                <a @click="bulkUploadClose"><i class="fa fa-close"></i></a>
            </template>
            <s-form @submit="bulkUploadAdd">
                <div class="row">
                    <div class="col-lg-12">
                        <validated-input @change="handleFileUpload" type="file" v-model="model.file"
                            label="Bulk Upload" :rules="{ required: true }" class="" border-radius="1">
                        </validated-input>
                    </div>
                    <div class="col-12 text-right mt-4">
                        <btn text="Save" :loading="addLoading" loading-text="Saving..." class="px-4"></btn>
                    </div>
                </div>
            </s-form>
        </modal>
        <modal ref="editcandidate" :no-close-on-backdrop="true" title="Edit Candidate" width="40r" header-color="primary">
            <s-form @submit="addEditItem">
                <div class="row">
                    <div class="col-lg-12">
                        <validated-input label="First Name" :rules="{required: true}"
                                         class="" border-radius="1" v-model="editingItem.first_name "></validated-input>
                    </div>
                    <div class="col-lg-12">
                        <validated-input label="Last Name" :rules="{required: true}"
                                         class="" border-radius="1" v-model="editingItem.last_name "></validated-input>
                    </div>
                    <div class="col-lg-12">
                        <validated-input label="Email" :rules="{required: true}"
                                         class="" border-radius="1" v-model="editingItem.email " type="email"></validated-input>
                    </div>
                    <div class="col-12 text-right mt-4">
                        <btn text="Save" :loading="editLoading" loading-text="Saving..." class="px-4"></btn>
                    </div>
                </div>
            </s-form>
        </modal>

        <delete-modal ref="deleteModal" enter-animation="animated slideInDown" exit-animation="animated slideOutUp" :url="deleteURL" :params="{id : itemID}"
                      @response="deleteComplete">
            <p> Are you sure ?</p>
            <template #loading>
                <div class="fl-x">
                    <loading-animation/>
                    <b class="ml-3">Please Wait...</b>
                </div>
            </template>
        </delete-modal>

    </div>

</template>

<script>
import axios from 'secure-axios';
import urls from '../../../data/urls';

export default {
    name: 'Candidate',
    data () {
        return {
            addEditURL: urls.mcq_interview.candidate.addEdit,
            listURL: urls.mcq_interview.candidate.list,
            deleteURL: urls.mcq_interview.candidate.delete,
            bulkUploadURL: urls.mcq_interview.candidate.bulkupload,
            itemID: null,
            addLoading: false,
            editLoading: false,
            editStatus: false,
            file: null,
            editingItem: {
                id: null,
                first_name: null,
                last_name: null,
                email: null
            },
            model: {
                first_name: null,
                last_name: null,
                email: null
            },
            fields: [
                {
                    name: 'first_name',
                    sortField: 'first_name',
                    title: 'First Name '
                },
                {
                    name: 'last_name',
                    sortField: 'last_name',
                    title: 'Last Name '
                },
                {
                    name: 'email',
                    sortField: 'email',
                    title: 'Email '
                },
                {
                    name: '__slot:actions',
                    title: 'Actions'
                }
            ]
        };
    },
    methods: {
        async addEditItem () {
            try {
                if (this.editStatus) {
                    this.editLoading = true;
                } else {
                    this.addLoading = true;
                }
                const response = await axios.form(this.addEditURL, this.editStatus ? this.editingItem : this.model);
                const result = response.data;
                if (result.success) {
                    if (this.editStatus) {
                        this.$notify(' Candidate Edited Successfully', 'Success', { type: 'success' });
                        this.editLoading = false;
                        this.editFormSuccess();
                    } else {
                        this.$notify('Candidate Added Successfully', 'Success', { type: 'success' });
                        this.addLoading = false;
                        this.addFormSuccess();
                    }
                } else {
                    const errors = response.data.errors;

                    for (const key in errors) {
                        if (Object.prototype.hasOwnProperty.call(errors, key)) {
                            let errorMessage = '';
                            if (key === '__all__') {
                                errorMessage = `${errors[key]}`;
                            } else {
                                errorMessage = `${key}:  ${errors[key]}`;
                            }
                            this.$notify(errorMessage, 'Warning', { type: 'warning' });
                        }
                    }
                    this.editLoading = false;
                    this.addLoading = false;
                }
            } catch (error) {
                this.$notify(
                    'Something Went Wrong..!!',
                    error.response.status + ' ' + error.response.statusText,
                    { type: 'danger' }
                );
                this.editLoading = false;
                this.addLoading = false;
            }
        },
        handleFileUpload (event) {
            this.file = event.target.files[0]; // Save the selected file
        },
        async bulkUploadAdd () {
            if (!this.file) {
                this.$notify('Please select a file for bulk upload.', 'Warning', { type: 'warning' });
                return;
            }

            try {
                this.addLoading = true;
                const response = await axios.form(this.bulkUploadURL, { file: this.file }, {
                    headers: { 'Content-Type': 'multipart/form-data' }
                });

                const result = response.data;
                if (result.success) {
                    this.$notify('Bulk Upload Successful', 'Success', { type: 'success' });
                    this.bulkUploadFormSuccess();
                } else {
                    this.handleErrors(result.errors);
                }
            } catch (error) {
                this.$notify(
                    'Something Went Wrong..!!',
                    `${error.response.status} ${error.response.statusText}`,
                    { type: 'danger' }
                );
            } finally {
                this.addLoading = false;
            }
        },
        handleErrors (errors) {
            for (const key in errors) {
                if (Object.prototype.hasOwnProperty.call(errors, key)) {
                    const errorMessage = key === '__all__' ? `${errors[key]}` : `${key}: ${errors[key]}`;
                    this.$notify(errorMessage, 'Warning', { type: 'warning' });
                }
            }
        },
        addFormSuccess () {
            this.$refs.addcandidate.close();
            this.model.first_name = null;
            this.model.last_name = null;
            this.model.email = null;
            this.$refs.table.refreshTable();
        },
        editFormSuccess () {
            this.editStatus = false;
            this.$refs.editcandidate.close();
            this.$refs.table.refreshTable();
        },
        editItem (item) {
            console.log('edit-item:>>>:', item);
            this.editStatus = true;
            this.editingItem.id = item.id;
            this.editingItem.first_name = item.first_name;
            this.editingItem.last_name = item.last_name;
            this.editingItem.email = item.email;
            this.$refs.editcandidate.show();
        },
        deleteItem (item) {
            this.itemID = item;
            this.$refs.deleteModal.show();
        },
        deleteComplete () {
            this.$refs.table.refreshTable();
            this.$notify('Deleted Successfully', 'Success', {
                type: 'success'
            });
            this.$refs.deleteModal.close();
        },
        addClose () {
            this.model.name = '';
            this.$refs.addcandidate.close();
        },
        bulkUploadClose () {
            this.model.name = '';
            this.$refs.bulkupload.close();
        },
        bulkUploadFormSuccess () {
            this.$refs.bulkupload.close();
            this.model.name = null;
            this.$refs.table.refreshTable();
        }
    }
};
</script>

<style scoped>

</style>
